var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group-steps-container" },
    [
      _c("HeaderBack", {
        staticClass: "header-back",
        attrs: {
          title: (_vm.handlerType === "edit" ? "编辑" : "新增") + "薪资组",
        },
        on: { back: _vm.goBack },
        scopedSlots: _vm._u([
          {
            key: "center",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "steps-box" },
                  [
                    _c(
                      "a-steps",
                      {
                        attrs: {
                          current: _vm.current,
                          size: "small",
                          type: "navigation",
                        },
                        on: { change: _vm.stepChange },
                      },
                      _vm._l(_vm.steps, function (item) {
                        return _c("a-step", {
                          key: item.id,
                          attrs: { title: item.title },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "steps-content" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm.current === 0
              ? _c("BasicInfo", {
                  ref: "BasicInfo",
                  attrs: {
                    current: _vm.current,
                    "handler-type": _vm.handlerType,
                  },
                  on: { changeGroupId: _vm.changeGroupId },
                })
              : _vm._e(),
            _vm.current === 1
              ? _c("SalaryPlans", {
                  attrs: { current: _vm.current, "group-id": _vm.groupId },
                })
              : _vm._e(),
            _vm.current === 2
              ? _c("SalaryRules", {
                  ref: "SalaryRules",
                  attrs: { current: _vm.current },
                  on: { savedSuccess: _vm.goBack },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "steps-action" },
        [
          _c(
            "a-space",
            [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.preserveNext } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.current === 2 ? "保存" : "保存且下一步") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }